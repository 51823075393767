<template>
  <CModal
    :title="condition.Id ? 'Cập nhật Cài đặt' : 'Thêm Cài đặt'"
    :show.sync="detailModal"
    :closeOnBackdrop="false"
    centered
  >
    <template v-slot:body-wrapper>
      <div
        class="modal-body overflow-auto"
        style="max-height: calc(100vh - 11.6rem);"
      >
        <CRow>
          <CCol md="12">
            <div class="form-group">
              <label>Chương trình</label>
              <v-select
                v-model="condition.ProgramId"
                :options="programs"
                :reduce="(p) => p.Id"
                label="Name"
                placeholder="Chọn chương trình"
                :class="{
                  'is-invalid border-danger rounded': !!inValidObject[
                    'condition.ProgramId'
                  ],
                }"
                :disabled="!!condition.Id"
              />
              <div class="invalid-feedback">
                {{ inValidObject["condition.ProgramId"] }}
              </div>
            </div>
          </CCol>

          <CCol md="8">
            <div class="form-group">
              <label>Cửa hàng</label>
              <v-select
                v-model="condition.ShopId"
                :options="shops"
                :reduce="(p) => p.Id"
                label="Name"
                placeholder="Chọn cửa hàng"
                :class="{
                  'is-invalid border-danger rounded': !!inValidObject[
                    'condition.ShopId'
                  ],
                }"
              />
              <div class="invalid-feedback">
                {{ inValidObject["condition.ShopId"] }}
              </div>
            </div>
          </CCol>

          <CCol md="4">
            <CInput
              type="number"
              label="Tỉ lệ trúng*"
              placeholder="Tỉ lệ trúng"
              v-model="condition.Rate"
              min="0"
              class="con-rate"
              :add-input-classes="{
                'is-invalid': !!inValidObject['condition.Rate'],
              }"
              :invalid-feedback="inValidObject['condition.Rate']"
            />
          </CCol>

          <CCol md="12">
            <div class="form-row">
              <CCol>
                <div role="group" class="form-group">
                  <label>
                    Thời gian bắt đầu
                  </label>
                  <div class="form-row">
                    <div :class="`${condition.FromDate ? 'col-10' : 'col-12'}`">
                      <datetime
                        type="date"
                        v-model="condition.FromDate"
                        format="dd/MM/yyyy"
                        :input-class="{
                          'form-control': true,
                          'is-invalid': !!inValidObject['condition.FromDate'],
                        }"
                        :class="{
                          'is-invalid': !!inValidObject['condition.FromDate'],
                        }"
                        value-zone="local"
                      />
                    </div>
                    <div v-if="condition.FromDate" class="col-2">
                      <button
                        type="button"
                        class="btn btn-light"
                        @click="condition.FromDate = null"
                      >
                        x
                      </button>
                    </div>
                  </div>
                  <div class="invalid-feedback">
                    {{ inValidObject["condition.FromDate"] }}
                  </div>
                </div>
              </CCol>

              <CCol>
                <div role="group" class="form-group">
                  <label>
                    Thời gian kết thúc
                  </label>
                  <div class="form-row">
                    <div :class="`${condition.ToDate ? 'col-10' : 'col-12'}`">
                      <datetime
                        type="date"
                        v-model="condition.ToDate"
                        format="dd/MM/yyyy"
                        :input-class="{
                          'form-control': true,
                          'is-invalid': !!inValidObject['condition.ToDate'],
                        }"
                        :class="{
                          'is-invalid': !!inValidObject['condition.ToDate'],
                        }"
                        value-zone="local"
                      />
                    </div>
                    <div v-if="condition.ToDate" class="col-2">
                      <button
                        type="button"
                        class="btn btn-light"
                        @click="condition.ToDate = null"
                      >
                        x
                      </button>
                    </div>
                  </div>
                  <div class="invalid-feedback">
                    {{ inValidObject["condition.ToDate"] }}
                  </div>
                </div>
              </CCol>
            </div>
          </CCol>

          <CCol md="12">
            <CTextarea
              label="Mô tả"
              placeholder="Mô tả"
              v-model="condition.Description"
              :add-input-classes="{
                'is-invalid': !!inValidObject['condition.Description'],
              }"
              :invalid-feedback="inValidObject['condition.Description']"
            />
          </CCol>

          <CCol md="12">
            <CSelect
              label="Trạng thái"
              placeholder="Chọn Trạng thái"
              :value.sync="condition.Status"
              :options="
                Object.keys($const.STATUS_TEXT).map((_) => {
                  return {
                    value: _,
                    label: $const.STATUS_TEXT[_],
                  };
                })
              "
              :add-input-classes="{
                'is-invalid': !!inValidObject['condition.Status'],
              }"
              :invalid-feedback="inValidObject['condition.Status']"
            />
          </CCol>
        </CRow>
      </div>
    </template>
    <template v-slot:footer>
      <CButton
        class="btn btn-primary"
        @click="saveCondition"
        :disabled="saving"
      >
        <CIcon name="cil-save" />
        Lưu thông tin
      </CButton>
      <div class="d-inline-block font-italic" v-if="saving">
        <CSpinner color="info" size="sm" class="ml-2" />
        Đang xử lý...
      </div>
    </template>
  </CModal>
</template>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

export default {
  components: {
    Datetime,
  },
  props: ["editing", "conditionId", "programs"],
  data() {
    return {
      detailModal: false,
      inValidObject: {},
      condition: this.newCondition(),
      saving: false,
      shops: [],
    };
  },
  async mounted() {
    this.shops = await this.loadShops();
  },
  watch: {
    async conditionId(val) {
      await this.loadConditionInfo(val);
    },
    editing(val) {
      this.detailModal = val;
      if (!val) {
        this.$emit("update:conditionId", null);
      }
    },
    detailModal(val) {
      this.$emit("update:editing", val);
    },
  },

  methods: {
    newCondition() {
      return {
        Id: 0,
        ProgramId: null,
        ShopId: null,
        Rate: 0,
        FromDate: null, //new Date().toISOString(),
        ToDate: null, //new Date().toISOString(),
        Description: null,
        Status: this.$const.STATUS.Active.toString(),
      };
    },

    async loadConditionInfo(conditionId) {
      try {
        this.inValidObject = {};
        if (conditionId) {
          let filters = [];
          conditionId && filters.push(`Id eq ${conditionId}`);
          let resp = await this.$http.get(`odata/Condition`, {
            params: {
              $top: 1,
              $filter: filters.join(" and "),
              $select:
                "Id,ProgramId,ShopId,Rate,FromDate,ToDate,Description,Status",
            },
          });
          if (resp && resp.status == 200) {
            let _ = resp.data.value[0];
            this.condition = {
              Id: _.Id,
              ProgramId: _.ProgramId,
              ShopId: _.ShopId,
              Rate: _.Rate,
              FromDate: _.FromDate,
              ToDate: _.ToDate,
              Description: _.Description,
              Status: _.Status.toString(),
            };
          }
        } else {
          this.condition = this.newCondition();
        }
      } catch (error) {
        alert(error);
      }
    },

    async saveCondition() {
      let condition = this.condition;
      this.inValidObject = this.checkValid(condition);
      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }

      this.saving = true;
      try {
        let conditionData = {
          ShopId: condition.ShopId,
          Rate: parseFloat(condition.Rate),
          FromDate: condition.FromDate ? condition.FromDate : null,
          ToDate: condition.ToDate ? condition.ToDate : null,
          Description: condition.Description,
          Status: parseInt(condition.Status),
        };
        // Customer
        if (!condition.Id) {
          // post
          conditionData.ProgramId = condition.ProgramId;
          let resp = await this.$http.post(`odata/Condition`, conditionData);
          if (resp && resp.status == 201) {
            this.detailModal = false;
            this.$emit("reload");
          }
        } else {
          // patch
          let resp = await this.$http.patch(
            `odata/Condition/${condition.Id}`,
            conditionData
          );
          if (resp && resp.status == 204) {
            this.detailModal = false;
            this.$emit("reload");
          }
        }
      } catch (error) {
        alert(error);
      }
      this.saving = false;
    },

    checkValid(condition) {
      let inValidObject = {};

      if (!condition.ProgramId)
        inValidObject["condition.ProgramId"] = "Vui lòng chọn Chương trình!";
      if (!condition.Rate || parseInt(condition.Rate) <= 0)
        inValidObject["condition.Rate"] = "Vui lòng nhập đúng Tỉ lệ trúng!";
      // if (!condition.FromDate)
      //   inValidObject["condition.FromDate"] = "Vui lòng nhập Ngày bắt đầu!";
      // if (!condition.ToDate)
      //   inValidObject["condition.ToDate"] = "Vui lòng nhập Ngày kết thúc!";
      if (!condition.Status)
        inValidObject["condition.Status"] = "Vui lòng chọn Trạng Thái!";

      return inValidObject;
    },

    async loadShops() {
      let shops = [];
      let active = this.$const.STATUS.Active;
      let resp = await this.$http.get("odata/Shop", {
        params: {
          $top: 200,
          $filter: `Status eq ${active}`,
          $select: "Id,Name",
        },
      });
      if (resp && resp.status == 200) {
        shops = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: _.Name,
          };
        });
      }
      return shops;
    },
  },
};
</script>

<style lang="scss" scoped>
.con-rate {
  &::after {
    content: "%";
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
    font-weight: bold;
  }
}
</style>
