var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('span',{staticClass:"font-weight-bold"},[_c('CIcon',{attrs:{"name":"cilTags"}}),_vm._v(" DS Cài đặt trúng thưởng ")],1),_c('CButton',{staticClass:"float-right",attrs:{"color":"success","size":"sm"},on:{"click":function($event){$event.preventDefault();return (function (e) {
            _vm.editing = true;
            _vm.conditionId = 0;
          })($event)}}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Thêm Cài đặt ")],1)],1),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"3"}},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-row"},[_c('label',{staticClass:"col-form-label col-4 text-right"},[_vm._v("Chương trình")]),_c('v-select',{staticClass:"col-8",attrs:{"options":_vm.programs,"reduce":function (p) { return p.Id; },"label":"Name","placeholder":"Chương trình"},model:{value:(_vm.programId),callback:function ($$v) {_vm.programId=$$v},expression:"programId"}})],1)])]),_c('CCol',{attrs:{"md":"3"}},[_c('CSelect',{attrs:{"label":"Trạng thái","placeholder":"Trạng thái","value":_vm.conditionStatus,"options":[
              { value: null, label: 'Tất cả' } ].concat( Object.keys(_vm.statusList).map(function (_) {
                return {
                  value: _,
                  label: _vm.statusList[_],
                };
              }) ),"horizontal":{
              label: 'col-4 text-right',
              input: 'col-8',
            }},on:{"update:value":function($event){_vm.conditionStatus=$event}}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CInput',{attrs:{"label":"Mô tả","placeholder":"Mô tả","horizontal":{
              label: 'col-4 text-right',
              input: 'col-8',
            }},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doFilter($event)}},model:{value:(_vm.conditionName),callback:function ($$v) {_vm.conditionName=$$v},expression:"conditionName"}})],1),_c('CCol',{staticClass:"text-center",attrs:{"md":"3"}},[_c('CButton',{staticClass:"mr-2 mb-3",attrs:{"color":"primary"},on:{"click":_vm.doFilter}},[_c('CIcon',{attrs:{"name":"cil-search","custom-classes":"c-icon m-0"}}),_vm._v(" Tìm kiếm ")],1),_c('CButton',{staticClass:"mb-3",attrs:{"color":"secondary"},on:{"click":_vm.clear}},[_c('CIcon',{attrs:{"name":"cil-x-circle","custom-classes":"c-icon m-0"}}),_vm._v(" Đặt lại ")],1)],1)],1),_c('odata-table',{ref:"lstCondition",attrs:{"url":"odata/Condition","filter":_vm.filter,"select":"Id,ShopId,Rate,FromDate,ToDate,Description,Status,CreatedAt","sortBy":"CreatedAt desc","expand":"Program($select=Name),Shop($select=Name)","pageSize":_vm.pageSize,"colSetting":{
          Action: {
            display: '#',
            sortable: false,
            style: 'min-width: 50px;',
          },
          ProgramName: {
            field: 'Program/Name',
            display: 'Chương trình',
            sortable: true,
            style: 'min-width: 120px',
          },
          ShopName: {
            field: 'Shop/Name',
            display: 'Cửa hàng',
            sortable: true,
            style: 'min-width: 120px',
          },
          Rate: {
            field: 'Rate',
            display: 'Tỉ lệ',
            sortable: true,
            style: 'min-width: 80px',
          },
          DateRange: {
            field: 'DateRange',
            display: 'Thời gian',
            sortable: false,
            style: 'min-width: 150px',
          },
          Description: {
            field: 'Description',
            display: 'Mô tả',
            sortable: true,
            style: 'min-width: 120px',
          },
          Status: {
            field: 'Status',
            display: 'Trạng thái',
            sortable: true,
            style: 'min-width: 100px',
          },
          CreatedAt: {
            field: 'CreatedAt',
            display: 'Ngày tạo',
            sortable: true,
            style: 'min-width: 120px',
          },
        }},scopedSlots:_vm._u([{key:"tbody",fn:function(ref){
        var rows = ref.rows;
return _vm._l((rows),function(row,i){return _c('tr',{key:("tr-" + i)},[_c('td',[_vm._v(_vm._s(i + 1 + (_vm.$refs.lstCondition.page - 1) * _vm.pageSize)+".")]),_c('td',[_vm._v(" "+_vm._s(row.Program.Name)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.Shop ? row.Shop.Name : "")+" ")]),_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:","title":("Cập nhật cài đặt: " + (i +
                    1 +
                    (_vm.$refs.lstCondition.page - 1) * _vm.pageSize))},on:{"click":function (e) {
                    _vm.conditionId = row.Id;
                    _vm.editing = true;
                  }}},[_c('CIcon',{attrs:{"name":"cil-pencil"}}),_vm._v(" "),_c('strong',[_vm._v(_vm._s(row.Rate)+"%")])],1)]),_c('td',{staticStyle:{"max-width":"200px"}},[(row.FromDate)?[_vm._v(" Từ: "+_vm._s(_vm.$moment .utc(row.FromDate) .utcOffset("-08:00") .format("DD/MM/YY"))+" ")]:_vm._e(),(row.ToDate)?[_vm._v(" - Đến: "+_vm._s(_vm.$moment .utc(row.ToDate) .utcOffset("-08:00") .format("DD/MM/YY"))+" ")]:_vm._e()],2),_c('td',[_vm._v(" "+_vm._s(row.Description)+" ")]),_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(row.Status)}},[_vm._v(" "+_vm._s(_vm.$const.STATUS_TEXT[row.Status])+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm.$moment .utc(row.CreatedAt) .utcOffset("-08:00") .format("DD/MM/YY HH:mm"))+" ")])])})}}])})],1)],1),_c('detail',{attrs:{"editing":_vm.editing,"conditionId":_vm.conditionId,"programs":_vm.programs},on:{"update:editing":function($event){_vm.editing=$event},"update:conditionId":function($event){_vm.conditionId=$event},"update:condition-id":function($event){_vm.conditionId=$event},"reload":function($event){return _vm.$refs.lstCondition.loadData()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }