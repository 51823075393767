<template>
  <div>
    <CCard>
      <CCardHeader>
        <span class="font-weight-bold">
          <CIcon name="cilTags" />
          DS Cài đặt trúng thưởng
        </span>
        <CButton
          color="success"
          size="sm"
          class="float-right"
          @click.prevent="
            (e) => {
              editing = true;
              conditionId = 0;
            }
          "
        >
          <CIcon name="cil-plus" />
          Thêm Cài đặt
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="3">
            <div class="form-group">
              <div class="form-row">
                <label class="col-form-label col-4 text-right"
                  >Chương trình</label
                >
                <v-select
                  v-model="programId"
                  :options="programs"
                  :reduce="(p) => p.Id"
                  label="Name"
                  placeholder="Chương trình"
                  class="col-8"
                />
              </div>
            </div>
          </CCol>
          <CCol md="3">
            <CSelect
              label="Trạng thái"
              placeholder="Trạng thái"
              :value.sync="conditionStatus"
              :options="[
                { value: null, label: 'Tất cả' },
                ...Object.keys(statusList).map((_) => {
                  return {
                    value: _,
                    label: statusList[_],
                  };
                }),
              ]"
              :horizontal="{
                label: 'col-4 text-right',
                input: 'col-8',
              }"
            />
          </CCol>
          <CCol md="3">
            <CInput
              label="Mô tả"
              placeholder="Mô tả"
              v-model="conditionName"
              :horizontal="{
                label: 'col-4 text-right',
                input: 'col-8',
              }"
              @keyup.enter="doFilter"
            />
          </CCol>
          <CCol md="3" class="text-center">
            <CButton color="primary" class="mr-2 mb-3" @click="doFilter">
              <CIcon name="cil-search" custom-classes="c-icon m-0" />
              Tìm kiếm
            </CButton>

            <CButton color="secondary" class="mb-3" @click="clear">
              <CIcon name="cil-x-circle" custom-classes="c-icon m-0" />
              Đặt lại
            </CButton>
          </CCol>
        </CRow>
        <odata-table
          ref="lstCondition"
          url="odata/Condition"
          :filter="filter"
          select="Id,ShopId,Rate,FromDate,ToDate,Description,Status,CreatedAt"
          sortBy="CreatedAt desc"
          expand="Program($select=Name),Shop($select=Name)"
          :pageSize="pageSize"
          :colSetting="{
            Action: {
              display: '#',
              sortable: false,
              style: 'min-width: 50px;',
            },
            ProgramName: {
              field: 'Program/Name',
              display: 'Chương trình',
              sortable: true,
              style: 'min-width: 120px',
            },
            ShopName: {
              field: 'Shop/Name',
              display: 'Cửa hàng',
              sortable: true,
              style: 'min-width: 120px',
            },
            Rate: {
              field: 'Rate',
              display: 'Tỉ lệ',
              sortable: true,
              style: 'min-width: 80px',
            },
            DateRange: {
              field: 'DateRange',
              display: 'Thời gian',
              sortable: false,
              style: 'min-width: 150px',
            },
            Description: {
              field: 'Description',
              display: 'Mô tả',
              sortable: true,
              style: 'min-width: 120px',
            },
            Status: {
              field: 'Status',
              display: 'Trạng thái',
              sortable: true,
              style: 'min-width: 100px',
            },
            CreatedAt: {
              field: 'CreatedAt',
              display: 'Ngày tạo',
              sortable: true,
              style: 'min-width: 120px',
            },
          }"
        >
          <template v-slot:tbody="{ rows }">
            <tr v-for="(row, i) in rows" :key="`tr-${i}`">
              <td>{{ i + 1 + ($refs.lstCondition.page - 1) * pageSize }}.</td>
              <td>
                {{ row.Program.Name }}
              </td>
              <td>
                {{ row.Shop ? row.Shop.Name : "" }}
              </td>
              <td>
                <a
                  href="javascript:"
                  class="text-primary"
                  :title="
                    `Cập nhật cài đặt: ${i +
                      1 +
                      ($refs.lstCondition.page - 1) * pageSize}`
                  "
                  @click="
                    (e) => {
                      conditionId = row.Id;
                      editing = true;
                    }
                  "
                >
                  <CIcon name="cil-pencil" />&nbsp;
                  <strong>{{ row.Rate }}%</strong>
                </a>
              </td>
              <td style="max-width:200px;">
                <template v-if="row.FromDate">
                  Từ:
                  {{
                    $moment
                      .utc(row.FromDate)
                      .utcOffset("-08:00")
                      .format("DD/MM/YY")
                  }}
                </template>
                <template v-if="row.ToDate">
                  - Đến:
                  {{
                    $moment
                      .utc(row.ToDate)
                      .utcOffset("-08:00")
                      .format("DD/MM/YY")
                  }}
                </template>
              </td>
              <td>
                {{ row.Description }}
              </td>
              <td>
                <CBadge :color="getBadge(row.Status)">
                  {{ $const.STATUS_TEXT[row.Status] }}
                </CBadge>
              </td>
              <td>
                {{
                  $moment
                    .utc(row.CreatedAt)
                    .utcOffset("-08:00")
                    .format("DD/MM/YY HH:mm")
                }}
              </td>
            </tr>
          </template>
        </odata-table>
      </CCardBody>
    </CCard>
    <detail
      :editing.sync="editing"
      :conditionId.sync="conditionId"
      :programs="programs"
      @reload="$refs.lstCondition.loadData()"
    />
  </div>
</template>

<script>
import odataTable from "@/components/odataTable.vue";
import detail from "./Detail.vue";

export default {
  name: "Condition",
  components: {
    odataTable,
    detail,
  },
  data() {
    return {
      editing: false,
      conditionId: 0,
      pageSize: 10,
      programs: [],
      programId: null,
      conditionStatus: null,
      statusList: this.$const.STATUS_TEXT,
      conditionName: null,
      filters: null,
    };
  },
  async mounted() {
    this.programs = await this.loadPrograms();
  },
  computed: {
    filter() {
      let filters = [];

      if (this.filters && this.filters.length) {
        filters = [...this.filters];
      }

      return filters.join(" and ");
    },
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case this.$const.STATUS.Active:
          return "success";
        case this.$const.STATUS.InActive:
          return "secondary";
        default:
          "primary";
      }
    },
    doFilter() {
      let filters = [];
      this.programId && filters.push(`ProgramId eq ${this.programId}`);
      this.conditionStatus && filters.push(`Status eq ${this.conditionStatus}`);
      this.conditionName &&
        filters.push(`contains(Description,'${this.conditionName}')`);
      filters.join(" and ");

      if (JSON.stringify(this.filters) != JSON.stringify(filters)) {
        this.filters = filters;
      } else {
        this.$refs.lstCondition.loadData();
      }
    },
    clear() {
      this.programId = null;
      this.conditionStatus = this.$const.STATUS.Active.toString();
      this.conditionName = null;
    },
    async loadPrograms() {
      let result = [];
      let active = this.$const.STATUS.Active;
      let resp = await this.$http.get("odata/LuckyProgram", {
        params: {
          $top: 1000,
          $filter: `Status eq ${active}`,
          $select: "Id,Name",
          $orderby: "CreatedAt desc",
        },
      });
      if (resp && resp.status == 200) {
        result = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: _.Name,
          };
        });
      }
      return result;
    },
  },
};
</script>
